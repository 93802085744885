import React, { useCallback, useEffect, useRef, useState } from 'react';
import { gql, useApolloClient } from '@apollo/client';
import moment from 'moment';
import { IconButton } from 'Components';
import { faDownload, faSync } from '@fortawesome/free-solid-svg-icons';
import { DarkSkyBlue } from 'libs/css';
import { CSVLink } from 'react-csv';
import { toast } from "react-toastify";
import { replaceDoubleQuotes } from 'libs/replaceDoubleQuotes';

const GET_STREAMING_DEVICES = gql`query getStreamingDevices($endCursor: String) {
    backend { id
        
        StreamEnabledDevices(first: 1000 after: $endCursor) {
            totalCount
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    last_seen
                    model
                    name
                    status
                    ip
                    CheckDMAZipCodeByIP {
                        currentDMA
                        currentZipCode
                        expectedZipCode
                        expectedDMAs
                    }
                    DeviceSpeed {
                        measured_at
                        speed
                    }
                    organization {
                        id
                        created_at
                        name
                        sf_obj_id
                        rooftop_sf_id
                        top_level_organization { id name }
                        hasSundayTicket: has_feature(feature: "sunday_ticket")
                        hasPeacock: has_feature(feature: "peacock")
                        shouldSignTNFTC: shouldSignTC(subscription_type: "thursday_night_football")
                        shouldSignParamountTC: shouldSignTC(subscription_type: "paramount")
                        shouldSignVictoryTC: shouldSignTC(subscription_type: "victory")
                        SalesforceMetadata {
                            id
                            metadata
                            accountMetadata { accountPrimaryContactEmail accountPhone }
                        }
                    }
                }
            }
        }
    }
}`;

const StreamingDevicesReport = () => {
    const [csvLoading, setCsvLoading] = useState(false);
    const [csvData, setCsvData] = useState(false);
    const client = useApolloClient();

    const handleDownload = useCallback(async () => {
        setCsvLoading(true);
        const data = await getData(client, GET_STREAMING_DEVICES);

        setCsvData(data.map(replaceDoubleQuotes));
        setCsvLoading(false);
    }, [])

    return <>
        <IconButton
            onClick={handleDownload}
            icon={csvLoading ? faSync : faDownload}
            spin={csvLoading}
            onHoverColor={DarkSkyBlue}
            className='p-2 w-auto'
            tooltip='Download Streaming Devices Report'
            id='streaming-devices-report-csv'
            text='Streaming Devices Report'
            disabled={csvLoading}
        />
        {csvData && !csvLoading && <GetStreamingDevicesCSV csvData={csvData} setCsvData={setCsvData} />}
    </>
}

async function getData(client, query) {
    let hasNextPage = true;
    let endCursor = undefined;
    let results = [];

    while (hasNextPage) {
        const { data, errors } = await client.query({ query, variables: { endCursor }, fetchPolicy: 'no-cache', errorPolicy: "all" });
        if (errors) toast.error("Some results might have partial information");
        results = results.concat(data.backend.StreamEnabledDevices.edges.map(e => e.node));
        hasNextPage = data.backend.StreamEnabledDevices.pageInfo.hasNextPage;
        endCursor = data.backend.StreamEnabledDevices.pageInfo.endCursor;
    }

    return await results;
}

const GetStreamingDevicesCSV = ({ setCsvData, csvData }) => {
    const link_ref = useRef(null);

    const devices = csvData?.map(device => {
        const organization = device.organization;

        const salesforceData = organization.SalesforceMetadata;

        const salesforceColumns = getSalesforceColumns(salesforceData);

        return {
            'Org ID': organization.id,
            'Org Name': organization.name,
            'Parent Org Name': organization.top_level_organization.name,
            'Org Created At': moment(organization.created_at).format('MM/DD/YYYY, HH:mm'),

            'Sunday Ticket': organization.hasSundayTicket.toString().toUpperCase(),
            'Peacock': organization.hasPeacock.toString().toUpperCase(),
            'TNF T&Cs Signed': (!organization.shouldSignTNFTC).toString().toUpperCase(),

            ...salesforceColumns,

            'Device Name': device.name,
            'Device Model': device.model,
            'Device Status': device.status,
            'Device Last Seen': device.last_seen,

            'Latest Speed Test Result': device.DeviceSpeed?.speed ?? '-',
            'Speed Test Measured At': device.DeviceSpeed?.measured_at ?? '-',

            'SF Obj ID': organization.sf_obj_id,
            'Roof Top SF ID': organization.rooftop_sf_id ?? '-',
            'IP': device.ip,

            'Current ZipCode': device.CheckDMAZipCodeByIP.currentZipCode ?? '-',
            'IP ZipCode': device.CheckDMAZipCodeByIP.expectedZipCode ?? '-',
            'Current DMA': device.CheckDMAZipCodeByIP.currentDMA ?? '-',
            'IP DMA': device.CheckDMAZipCodeByIP.expectedDMAs?.join(',') ?? '-',
        }
    });

    useEffect(() => {
        setTimeout(() => {
            link_ref.current.link.click();
            setCsvData(false);
        }, 100);
    }, [link_ref, setCsvData]);

    return <CSVLink data={devices} filename={`streaming_devices_report-${moment().format('MM-DD-YYYY')}.csv`} target="_blank" ref={link_ref} className="d-none" />
}

const getSalesforceColumns = (data) => {
    if (!data) return {
        // Account
        'Primary Contact': '-',
        'Phone': '-',
        // Location
        'Address': '-',
        'City': '-',
        'State': '-',
        'ZipCode': '-',
        'FCO': '-',
        'FCO Tier': '-',
        'FCO Confirmed': '-',
        'Streaming Certified': '-',
        'Live Streaming Notes': '-'
    }
    const locationData = data.metadata.details;
    const accountData = data.accountMetadata;

    return {
        // Account
        'Primary Contact': accountData?.accountPrimaryContactEmail ?? '-',
        'Phone': accountData?.accountPhone ?? '-',

        // Location
        'Address': locationData?.Address_1__c ?? '-',
        'City': locationData?.City__c ?? '-',
        'State': locationData?.State_Province_new__c ?? '-',
        'ZipCode': locationData?.Zip_Postal_Code__c ?? '-',
        'FCO': locationData?.Fire_Code_Occupancy2__c ?? '-',
        'FCO Tier': locationData?.Fire_Code_Occupancy__c ?? '-',
        'FCO Confirmed': locationData?.FCO_Confirmed__c?.toString().toUpperCase() ?? '-',
        'Streaming Certified': locationData?.Streaming_Certified__c ?? '-',
        'Live Streaming Notes': locationData?.Live_Streaming_Notes__c ?? '-'
    }
}

export default StreamingDevicesReport;